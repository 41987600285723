$color-theme-red: #f15a31;
$color-theme-link-red: #f9544d;
$color-theme-yellow: #e9df97;
$color-theme-gray: #adb3b8;
$color-theme-gray2: #646F78;
$color-theme-dark-gray: #1a1a1a;

//$breakpoint-4k: 1920px; // Test only
$breakpoint-4k: 3840px; // Test only

@font-face {
    font-family: 'ScalaSansWebPro';
    src: url('/fonts/ScalaSansWebPro.eot') format('eot'),
    url('/fonts/ScalaSansWebPro.woff') format('woff');
    font-weight: 100 700;
    font-style: normal;
}

@font-face {
    font-family: 'ScalaSansWebPro-Bold';
    src: url('/fonts/ScalaSansWebPro-Bold.eot') format('eot'),
    url('/fonts/ScalaSansWebPro-Bold.woff') format('woff');
    font-weight: 100 700;
    font-style: normal;
}

@font-face {
    font-family: 'ScalaSansOT-Light';
    src: url('/fonts/ScalaSansOT-Light.otf') format('opentype');
    font-weight: 100 700;
    font-style: normal;
}

@font-face {
    font-family: 'ScalaSansOT-Light-Ita';
    src: url('/fonts/ScalaSansOT-LightIta.otf') format('opentype');
    font-weight: 100 700;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica LT Pro Ultra Compressed';
    src: url('/fonts/Linotype_-_HelveticaLTPro-UltComp.otf') format('opentype');
    font-weight: 100 700;
    font-style: normal;
    ascent-override: 100%;
}

@font-face {
    font-family: 'Helvetica LT Std Ultra Compressed';
    src: url('/fonts/Linotype_-_HelveticaLTStd-UltraComp.otf') format('opentype');
    font-weight: 100 700;
    font-style: normal;
    ascent-override: 90%;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro Condensed';
    src: url('/fonts/Linotype_-_HelveticaNeueLTPro-Cn.otf') format('opentype');
    font-weight: 100 700;
    font-style: normal;
    ascent-override: 90%;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro Medium Condensed';
    src: url('/fonts/Linotype_-_HelveticaNeueLTPro-MdCn.otf') format('opentype');
    font-weight: 100 700;
    font-style: normal;
    ascent-override: 90%;
}

body {
    background-color: #000;
    user-select: none;
}

#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden; //swiper package transforms make scrollbar flash in and out.
}

h1, h2, h3, h4 {
    font-family: Helvetica LT Std Ultra Compressed, sans-serif;
}

p, li {
    font-family: Helvetica Neue LT Pro Medium Condensed, sans-serif;
}

.bg-dark-gray {
    background-color: $color-theme-dark-gray;
}

.bg-black {
    background-color: #000;
}

.text-red {
    color: $color-theme-red;
}

.text-yellow {
    color: $color-theme-yellow;
}

.text-gray {
    color: $color-theme-gray;
}

.btn-intro{
    max-width: 20.9vw;
    max-height: 3vw;
    padding: 0.3vw 4.2vw;
    margin-top: 18.8vw;
    background-color: #F15A31;
    border-radius: 0;
    font-family: Helvetica LT Pro Ultra Compressed;
    font-size: 1.6vw;
    text-transform: uppercase;
    letter-spacing: 0.06vw;
    color: #fff;
    box-shadow: 0.2vw 0.2vw 0.4vw #00000087;

    &:hover, &:visited, &:active{
        text-decoration: none;
        color: #fff;
    }
}

.video-js{
    width: 100%;
    height: 100%;
}

.navbar {

    &.navbar-theme-red {

        background-color: $color-theme-red;
        padding: 0;
        padding-left: 3.7vw;

        /*@media screen and (min-width: $breakpoint-4k) {
            padding-left: 150px;
        }*/

        .navbar-nav{
            padding: 0 10vw 0 3.3vw;
            justify-content: space-between;
        }

        .nav-item {
            flex: 0;

            .active-item-indicator {
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 12px 8px 0 8px;
                border-color: #ffffff transparent transparent transparent;
            }
        }

        a.nav-link {

            color: $color-theme-yellow;
            text-transform: uppercase;
            font-size: 1.56vw;
            font-family: Helvetica LT Std Ultra Compressed, sans-serif;
            letter-spacing: 0.02em;
            padding: 0.6vw 0.6vw 0.3vw;

            &:hover {
                color: #FFF;
                transition: all 0.25s;
            }

            &.active {
                color: #FFF;
            }

            /*@media screen and (min-width: $breakpoint-4k) {

                font-size: 64px;
            }*/
        }
    }
}

.category-nav-wrapper {

    margin-top: 0.18vw;

    .navbar-home-icon{

        padding-top: 0;

        .home-icon {
            height: 1.5vw;
            width: 1.5vw;
        }
    }

    /*@media screen and (min-width: $breakpoint-4k) {

        height: 122px;
        margin-top: 12px;
    }*/
}

.project-nav-wrapper {

    //display: flex;
    height: 4.9vw;

    .all-markets-text {
        height: 4.9vw;
        padding: 0.9vw 0.9vw 0 0.9vw;
        font-size: 2.158vw;
        font-family: Helvetica LT Pro Ultra Compressed, sans-serif;
        text-transform: uppercase;
        color: $color-theme-gray2;
    }

    /*@media screen and (min-width: $breakpoint-4k) {
        height: 189px;
    }*/

    .project-nav-item {

        width: 26.05vw;
        margin-right: 8px;

        .project-thumb {
            background-size: cover;
            background-position: center center;
        }

        .project-name {
            font-size: 1.04vw;
        }

        small{
            font-size: 0.84vw;
        }

        &.active {

            .project-name {
                font-size: 1.04vw;
                color: $color-theme-red !important;
            }
        }

        /*@media screen and (min-width: $breakpoint-4k) {

        }*/
    }

}

.map-test {
    width: 100%;
    height: 100%;
    background-image: url("/images/map-test.png");
    background-position: center center;
    background-size: cover;
}

.map-container {
    height: 86.5vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-items: center;
}

.map-container-static {

    padding-right: 0;
    padding-left: 3.7vw;
    overflow: hidden;
    //max-height: 1836px;

    img {
        width: 100%;
        height: auto;
    }
}

@media all and (display-mode: fullscreen) {
    .map-container {
        height: 87.8vh;
    }
}

.video-container {
    padding: 0;
    max-height: 47.9vw;
    .video-js{

        video {
            object-fit: cover;
        }
    }
}

.imageRow {

    .col {
        background-size: cover;
        background-position: center center;
    }
}

.btn-project-back-next {

    background-color: $color-theme-red;
    color: #FFF;
    padding: 0.3vw 0.8vw;
    border-radius: 1rem;
    width: 2.6vw;

    display: inline-block;


    svg {
        height: 0.75vw;
    }
}

.main-row{
    max-height: 47.9vw;
    overflow: hidden;
}

.column-left {

    display: flex;
    flex-direction: column;
    flex: 0 0 30%;
    max-width: 30%; // override bootstrap col width

    /*padding-left: 48px;
    padding-right: 35px;
    padding-top: 43px;*/
    padding: 3.5vw 2.6vw 0 3.9vw;

    h2{
        font-size: 2.1vw;
        text-transform: uppercase;
    }

    .category-header {
        font-size: 1.6vw;
        letter-spacing: 0.02vw;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .project-name-header {
        font-size: 1.65vw;
        font-family: Helvetica Neue LT Pro Medium Condensed, sans-serif;
        margin-bottom: 0.9vw;
    }

    .project-subheader {
        font-size: 1.2vw;
        font-family: Helvetica Neue LT Pro Medium Condensed, sans-serif;
        margin-bottom: 1.3vw;
    }

    p {
        font-family: Helvetica Neue LT Pro Condensed, sans-serif;
        font-size: 1.2vw;
        line-height: 1.5vw;
    }

    ul {
        padding-left: 1.2vw;
    }

    li {
        margin-bottom: 0.5vw;
        font-size: 1.2vw;
        line-height: 1.5vw;
        font-family: Helvetica Neue LT Pro Condensed, sans-serif;
    }

    .prompt{
        margin-top: 2.2vw;
    }

    .pagination{
        flex: 1 1;
        display: flex;
        justify-content: center;
        align-items: end;
        padding-bottom: 1.3vw;
        gap: 0.5vw;
    }

    /*@media screen and (min-width: $breakpoint-4k) {

        padding-left: 148px;
        padding-right: 148px;
        padding-top: 148px;

        .category-header {
            font-size: 61px;
        }

        .project-name-header {
            font-size: 61px;
            margin-bottom: 20px;
        }

        .project-subheader {
            font-size: 48px;
            margin-bottom: 20px;
        }

        p {
            font-size: 3em;
            line-height: 1.11em;
        }

        li {
            font-size: 3em;
            line-height: 1.11em;
        }

    }*/

}

.h-63 {
    height: 63.5%;
}

.h-36 {
    height: 36.5%;
}

.mySwiperScrollContainer {

    //height: 600px;

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        padding-right: 24px;
        //font-size: 12px;
        height: auto;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
}

.video-prompt-wrapper {

    top: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;

    display: flex;
    justify-content: center;
    align-items: center;

    .video-prompt {
        color: #FFF;
        font-size: 1.85vw;
        font-family: Helvetica Neue LT Pro Medium Condensed, sans-serif;
    }
}

.s-container{
    padding: 0.41vw;
    a:hover, a:active{
        text-decoration: none;
    }
}
